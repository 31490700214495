import React, { Component, Fragment } from "react";
import FretboardWrapper from "./components/fretboard/fretboard";
import notesArray from "./constants/notesArray";
import "./App.css";
import TuningPeg from "./components/headstock/tuningPeg/tuningPeg";
import Headstock from "./components/headstock/headstock/headstock";
import ScaleTypes from "./components/scale/scaleTypes/scaleTypes";
import PianoSection from "./components/chord/pianoSection/pianoSection";
import ChordSection from "./components/chord/chordSection/chordSection";
import * as Key from "tonal-key";
import Tonal, { midi, transpose, scale, Scale } from "tonal";
import { scaleNotes, chordNotes } from "react-fretboard";
import { Helmet } from "react-helmet";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      availableNotes: [],
      tuning: ["E2", "A2", "D3", "G3", "B3", "E4"],
      currentScale: "major",
      showHeadstock: false,
      currentKey: "C",
      showPositions: false
    };
  }

  componentWillMount() {
    const selectedNotes = scale(this.state.currentScale).map(
      transpose(this.state.currentKey)
    );

    this.setState({ availableNotes: notesArray });
  }

  updateScaleNotes = (scale, rootNote) => {
    return Scale.notes(rootNote + " " + scale);
  };

  updateScale = scale => this.setState({ currentScale: scale });

  updateKey = key => this.setState({ currentKey: key });

  updateTuning = noteArray => this.setState({ tuning: noteArray });

  updateString = (note, index) => {
    let newTuning = this.state.tuning;
    newTuning[index] = note;
    return this.setState({ tuning: newTuning });
    debugger;
  };

  render() {
    const {
      selectedNotes,
      availableNotes,
      tuning,
      currentScale,
      showHeadstock,
      currentKey
    } = this.state;

    return (
      <div className="page-container">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Fretify | Guitar Modes and Chords in any Tuning</title>
          <meta
            name="description"
            content="Fretify transposes guitar modes and chords into whatever tuning you like. Use the fretboard map to explore any mode in any alternate tuning."
          />
          // <link rel="canonical" href="https://www.fretify.com" />
        </Helmet>

        <div className="container">
          <h1>Fretify</h1>
          <h2>Guitar Modes and Chords in any Tuning</h2>
          <p>
            Set the fretboard to your guitar tuning and view the scale
            structure!
          </p>
          <div className="scaletypes-container">
            <ScaleTypes
              value={currentScale}
              updateScale={this.updateScale}
              updateKey={this.updateKey}
              keyValue={currentKey}
            />{" "}
            <button
              onClick={() => this.setState({ showHeadstock: !showHeadstock })}
            >
              {showHeadstock ? "Close" : "Tuning"}
            </button>
          </div>

          {showHeadstock && (
            <Fragment>
              <p>Current tuning = {JSON.stringify(tuning)}</p>

              <Headstock
                selectedNotes={selectedNotes}
                updateString={this.updateString}
                tuning={tuning}
                updateTuning={this.updateTuning}
              />
            </Fragment>
          )}
          <hr className="divider" />
          <div className="fretboard-section">
            <FretboardWrapper
              selectedNotes={scaleNotes(
                currentKey,
                currentScale,
                this.state.showPositions
              )}
              tuning={tuning}
            />
          </div>
          <button
            onClick={() =>
              this.setState({ showPositions: !this.state.showPositions })
            }
          >
            {this.state.showPositions ? "show notes" : "show positions"}
          </button>
          <hr className="divider" />

          <div className="chord-section">
            <ChordSection
              chordArray={Key.chords(currentKey + currentScale)}
              tuning={tuning}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default App;
