import React, { Component } from "react";
import { chordNotes } from "react-fretboard";
import { Chord } from "tonal";

class SingleChord extends Component {
  // componentDidUpdate() {
  //   if (this.props.selected) {
  //     this.props.selectChord(this.props.chord, this.props.index);
  //   }
  // }

  render() {
    const { chord, selectChord, detail, index, selected } = this.props;

    return (
      <button
        onClick={() => selectChord(chord, index)}
        className={"singlechord" + (selected && " selected")}
      >
        <h3>{index + 1}</h3>
        <h4>{chord}</h4>

        {detail && <p>Notes: {Chord.notes(chord)}</p>}
      </button>
    );
  }
}

export default SingleChord;
