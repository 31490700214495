import _ from "lodash";

const rawNotes = [
  "C",
  "C#",
  "D",
  "D#",
  "E",
  "F",
  "F#",
  "G",
  "G#",
  "A",
  "A#",
  "B"
];

const numberOfOctaves = 8;
const octaveOffset = 3;

let notesArray = [];

//loop constructs the available notes for multiple octaves
for (let i = 0; i < numberOfOctaves; i++) {
  let currentOctave = [];
  for (let j = 0; j < rawNotes.length; j++) {
    currentOctave.push(rawNotes[j] + (i - octaveOffset));
  }
  notesArray = _.concat(notesArray, currentOctave);
}

export default notesArray;
