import React, { Component } from "react";
import TuningPeg from "../tuningPeg/tuningPeg";

const tuningPresets = [
  { name: "Standard Tuning", value: ["E2", "A2", "D3", "G3", "B3", "E4"] },
  { name: "Drop D", value: ["D2", "A2", "D3", "G3", "B3", "E4"] },
  { name: "Drop C", value: ["C2", "G2", "C3", "F3", "A3", "D3"] }
];

class Headstock extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { selectedNotes, updateString, tuning } = this.props;

    return (
      <div className="headstock-main">
        {tuning.map((note, i) => (
          <TuningPeg
            key={note + i + i + "C"}
            value={tuning[i]}
            updateString={updateString}
            stringIndex={i}
          />
        ))}

        <label>
          Presets
          <select
            value={tuning}
            onChange={e => this.props.updateTuning(e.target.value.split(","))}
            // onChange={e => console.log([e.target.value])}
          >
            {tuningPresets.map(preset => (
              <option value={preset.value} key={preset.name}>
                {preset.name}
              </option>
            ))}
          </select>
        </label>
      </div>
    );
  }
}

export default Headstock;
