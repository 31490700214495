import React, { Component, Fragment } from "react";
import { scaleNotes, chordNotes } from "react-fretboard";
import FretboardWrapper from "../../fretboard/fretboard";
import SingleChord from "../singleChord/singleChord";

class ChordSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedChord: this.props.chordArray[0],
      chordNotes: [],
      highlighting: false,
      positions: false,
      chordIndex: 0,
      detailView: false
    };
  }

  componentDidMount() {
    this.updateChordNotes(this.state.selectedChord);
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.chordArray !== prevProps.chordArray) {
      this.selectChord(
        this.props.chordArray[this.state.chordIndex],
        this.state.chordIndex
      );
    }
  }

  updateChordNotes = chord => {
    this.setState({
      chordNotes: chordNotes(
        chord,
        this.state.positions,
        this.state.highlighting
      ),
      chordNotesHighlighted: chordNotes(chord, false, true),
      chordNotesPositioned: chordNotes(chord, true, false),
      chordNotesPositionedHighlighted: chordNotes(chord, true, true)
    });
  };

  togglePositions = () => {
    this.setState({ positions: !this.state.positions });
  };

  toggleHighlighting = () => {
    this.setState({ highlighting: !this.state.highlighting });
  };

  fretboardController = () => {
    const {
      positions,
      highlighting,
      chordNotes,
      chordNotesHighlighted,
      chordNotesPositioned,
      chordNotesPositionedHighlighted
    } = this.state;

    if (positions && highlighting) {
      return chordNotesPositionedHighlighted;
    } else if (positions) {
      return chordNotesPositioned;
    } else if (highlighting) {
      return chordNotesHighlighted;
    }
    return chordNotes;
  };

  selectChord = (chord, index) => {
    this.setState({ selectedChord: chord, chordIndex: index });
    this.updateChordNotes(chord);
  };

  isSelected = chord => chord === this.state.chordIndex;

  render() {
    const { chordArray, tuning } = this.props;
    const {
      chordNotes,
      chordNotesHighlighted,
      chordNotesPositioned,
      chordNotesPositionedHighlighted,
      positions,
      highlighting,
      selectedChord,
      chordIndex,
      detailView
    } = this.state;

    return (
      <Fragment>
        <div className="single-chord-list">
          {chordArray.map((chord, i) => (
            <SingleChord
              chord={chord}
              selectChord={this.selectChord}
              index={i}
              selected={this.isSelected(i)}
              detail={detailView}
            />
          ))}
          <button
            onClick={() =>
              this.setState({ detailView: !this.state.detailView })
            }
          >
            Detail
          </button>
        </div>
        <FretboardWrapper
          selectedNotes={this.fretboardController()}
          tuning={tuning}
          // highlightOctaves={true}
        />
        <div className="chord-section-controls">
          <button onClick={() => this.togglePositions()}>
            Toggle Positionsindex
          </button>
          <button onClick={() => this.toggleHighlighting()}>
            Toggle highlighting
          </button>
        </div>
      </Fragment>
    );
  }
}

export default ChordSection;
