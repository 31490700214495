import React, { Component } from "react";
import * as Scale from "tonal-scale";

const scaleTypes = Scale.names();

const manualScaleTypes = [
  "major",
  "minor",
  "ionian",
  "dorian",
  "phrygian",
  "lydian",
  "mixolydian",
  "aeolian",
  "locrian"
];

const rawNotes = [
  { note: "C", oct: "2" },
  { note: "C#", oct: "2" },
  { note: "D", oct: "2" },
  { note: "D#", oct: "2" },
  { note: "E", oct: "2" },
  { note: "F", oct: "2" },
  { note: "F#", oct: "2" },
  { note: "G", oct: "2" },
  { note: "G#", oct: "2" },
  { note: "A", oct: "2" },
  { note: "A#", oct: "2" },
  { note: "B", oct: "2" }
];

class ScaleTypes extends Component {
  render() {
    const { value, updateScale, updateKey, keyValue } = this.props;

    return (
      <div className="scale-controls">
        <label>
          Select Key
          <select onChange={e => updateKey(e.target.value)} value={keyValue}>
            {rawNotes.map((note, i) => (
              <option key={note + i + "key"} value={note.note}>
                {note.note}
              </option>
            ))}
          </select>
        </label>

        <label>
          Select Scale
          <select value={value} onChange={e => updateScale(e.target.value)}>
            {manualScaleTypes.map((scale, i) => (
              <option value={scale} key={scale + i + "B"}>
                {scale}
              </option>
            ))}
          </select>
        </label>
      </div>
    );
  }
}

export default ScaleTypes;
