import React, { Component, Fragment } from "react";
import notesArray from "../../../constants/notesArray";

class TuningPeg extends Component {
  render() {
    const { value, updateString, stringIndex } = this.props;

    return (
      <Fragment>
        <p>String {stringIndex + 1}</p>
        <select
          value={value}
          onChange={e => updateString(e.target.value, stringIndex)}
        >
          {notesArray.map((note, i) => (
            <option value={note} key={note + i + stringIndex + "A"}>
              {note}
            </option>
          ))}
        </select>
      </Fragment>
    );
  }
}

export default TuningPeg;
