import React, { Component } from "react";
import Fretboard, { chordNotes } from "react-fretboard";

class FretboardWrapper extends Component {
  render() {
    const { selectedNotes, tuning, showNotes, highlightOctaves } = this.props;

    return (
      <Fretboard
        skinType="strings"
        selectedNotes={selectedNotes}
        nrOfFrets={13}
        tuning={tuning}
        showNotes={showNotes}
        showSelectionLabels={true}
        highlightOctaves={highlightOctaves}
        // skinType={'boxes'}
        className="fretboard-styles"
      />
    );
  }
}

export default FretboardWrapper;
